import { api } from './client';
import dayjs, { Dayjs } from 'dayjs';
import {
    CryptoInfo,
    Order,
    Post,
    PriceHistory,
    ProductInfo,
    ProductResult,
    Role,
    User,
    GetPostsParams,
    GetUserProfileResponse,
    Comment,
    RoiHistory,
    GetUserStatsResponse,
    Analysis,
    UserOrigin,
    Like,
    Reply,
    SubscriptionResponse,
    SubscriptionStatus,
    MonthlyRevenue,
    PayoutData,
    AdminData,
    Notification,
    Connection,
    Payments,
    Transfer,
    PromoCodeData,
    ProductCategory,
    Language,
    SubscriptionFormula, NotificationType, NotificationsData
} from '../types/types';
import { objectKeysToParamList } from '@/utils/post';
import { wait } from '@testing-library/user-event/dist/utils';
import {AxiosResponse} from "axios";
export interface CreateUserParams {
    role: Role;
    origin: UserOrigin;
    email?: string;
    password?: string;
    pseudo?: string;
    firstName?: string;
    lastName?: string;
    language?: Language;
    mentorPrice?: string;
    subscriptionFormula?: SubscriptionFormula;
}

export const createUser = (user: CreateUserParams) => {
    // user data is decoded by backend from the user token
    return api.post<User>('/users', user);
};

export const getUser = () => {
    // user id is decoded by backend from the user token
    return api.get<User>(`/users`);
};

export const updateUser = (user: Partial<User>) => {
    // user id is decoded by backend from the user token
    return api.put<User>('/users', user);
};

export const createPost = (post: Partial<Post>) => {
    return api.post<{ post: Post; user: User }>('/posts', post);
};

export const getUserPosts = (
    {
        userId,
        limit = 10,
        cursor,
        options,
        orderStatus,
        orderBy,
    }: GetPostsParams = { userId: '' }
) => {
    // user id is decoded by backend from the user token
    return api.get<Post[]>(`/users/${userId}/posts`, {
        params: {
            limit,
            cursor,
            options: objectKeysToParamList({ ...options }),
            orderStatus,
            orderBy,
        },
    });
};

export const searchProducts = (
    text: string,
    limit: number = 10,
    exchange: string = ''
) => {
    return api.get<ProductResult[]>(
        `/search/products?query=${text}&limit=${limit}&exchange=${exchange}${
            exchange === ProductCategory.CRYPTO ? '&cryptoVersion=2' : ''
        }`
    );
};

export const getProductInfo = ({
    ticker,
    exchange,
    includeMarketStatus = false,
}: {
    ticker: string;
    exchange: string;
    includeMarketStatus?: boolean;
}) => {
    return api.get<(ProductInfo & CryptoInfo)[]>(`/products/${ticker}`, {
        params: {
            exchange,
            ...(includeMarketStatus && { options: 'includeMarketStatus' }),
        },
    });
};

export const getRealTimeCapital = () => {
    return api.get<{ capital: number }>(`/users/capital`);
};

export const getProductHistory = (
    ticker: string,
    from: Date | Dayjs,
    to: Date | Dayjs
) => {
    return api.get<PriceHistory>(`/products/history/${ticker}`, {
        params: {
            from: dayjs(from).format('YYYY-MM-DD'),
            to: dayjs(to).format('YYYY-MM-DD'),
        },
    });
};

export const closeOrder = (id: string, analysis?: Partial<Analysis>) => {
    return api.patch<any>(`/orders/${id}`, {
        ...analysis,
    });
};

export const updateThreshold = (id: string, threshold?: Partial<Order>) => {
    return api.patch<any>(`/orders/threshold/${id}`, {
        ...threshold,
    });
};

export const getOrder = (id: string) => {
    return api.get<{ order: Order }>(`/orders/${id}`);
};

export const deletePost = (id: number | string) => {
    return api.delete<any>(`/posts/${id}`);
};

export type GetTraderParams = {
    limit?: number;
    cursor?: string;
    options?: string;
    language?: Language
};

export const getTraders = ({
    limit,
    cursor,
    options,
    language
}: GetTraderParams = {}) => {
    return api.get<User[]>('users/traders', {
        params: {
            limit,
            cursor,
            options,
            language,
        },
    });
};

export const getUserRoi = (userId: string) => {
    return api.get<{ roi: number }>(`users/roi/${userId}`);
};

export interface GetTradersPostsOptions {
    onlyFavorites?: boolean;
}

export interface GetTradersPostsParams {
    limit?: number;
    cursor?: string;
    options?: GetTradersPostsOptions;
    language?: Language;
}

export const getTradersPosts = ({
    limit = 10,
    cursor,
    options,
    language
}: GetTradersPostsParams = {}) => {
    return api.get<Post[]>('/posts/traders', {
        params: {
            limit,
            cursor,
            options: objectKeysToParamList({ ...options }),
            language,
        },
    });
};

export const getUserProfile = (userId: string) => {
    return api.get<GetUserProfileResponse>(`/users/profile/${userId}`);
};

export const followTrader = (traderId: string) => {
    return api.put(`/users/traders/${traderId}`);
};

export const unfollowTrader = (traderId: string) => {
    return api.delete(`/users/traders/${traderId}`);
};

export const saveComment = (postId: string, comment: string) => {
    return api.post<Comment[]>(`/posts/${postId}/comments`, { comment });
};

export const getPostComments = (postId: string) => {
    return api.get<Comment[]>(`/posts/${postId}/comments`);
};

export const saveReply = (commentId: number, reply: string) => {
    return api.post<Reply[]>(`/comments/${commentId}/replies`, { reply });
};

export const getReplies = (commentId: number) => {
    return api.get<Reply[]>(`/comments/${commentId}/replies`);
};

export const saveLike = (postId: string) => {
    return api.post(`/posts/${postId}/likes`);
};

export const deleteLike = (postId: string) => {
    return api.delete(`/posts/${postId}/likes`);
};

export const getLikes = (postId: string) => {
    return api.get<Like[]>(`/posts/${postId}/likes`);
};

export const getRoiHistory = (userId: string, from: Date) => {
    return api.get<RoiHistory[]>(`/users/roi/${userId}/history`, {
        params: {
            from,
        },
    });
};

export interface GetUserStatsOptions {
    breakdown?: boolean;
    financial?: boolean;
    postsTypesCount?: boolean;
}

export const getUserStats = (userId: string, options: GetUserStatsOptions) => {
    return api.get<GetUserStatsResponse>(`/users/${userId}/stats`, {
        params: {
            options: objectKeysToParamList({ ...options }),
        },
    });
};

export const saveFavoritePost = (postId: string) => {
    return api.post(`/posts/${postId}/favorite`);
};

export const deleteFavoritePost = (postId: string) => {
    return api.delete(`/posts/${postId}/favorite`);
};

export const getFavoritePosts = () => {
    return api.get<Post[]>(`/users/favoritePosts`);
};

export const getPost = (postId: string) => {
    return api.get<Post>(`/posts/${postId}`);
};

export interface CreateStripeCustomerParams {
    customerId: string;
}

export const createStripeCustomer = () => {
    return api.post<SubscriptionResponse>(`/subscriptions/`);
};

export const cancelSubscription = (traderId: string) => {
    return api.delete(`/subscriptions/${traderId}/`);
};

export const getConnectionStatus = (traderId: string) => {
    return api.get<{ status: SubscriptionStatus }>(
        `/subscriptions/${traderId}/status`
    );
};

export const checkForSubscriptionActiveStatus = (traderId: string) => {
    const MAX_RETRIES = 40;
    const MAX_DURATION = 40 * 1000; // 40s

    let retriesCount = 0;
    let startTime = Date.now();

    return new Promise((resolve, reject) => {
        const getStatus = async () => {
            try {
                const duration = Date.now() - startTime;
                retriesCount++;

                const { data } = await getConnectionStatus(traderId);

                if (retriesCount > MAX_RETRIES || duration > MAX_DURATION) {
                    resolve(data.status);
                    return;
                }

                if (data.status === SubscriptionStatus.ACTIVE) {
                    resolve(data.status);
                    return;
                }

                await wait(1000);
                getStatus();
            } catch (error) {
                reject(error);
            }
        };
        getStatus();
    });
};

export const searchTraders = (string: string) => {
    return api.get<User[]>(`/search/${string}/traders`);
};

export const getMonthlyRevenue = () => {
    return api.get<MonthlyRevenue[]>(`/users/traders/revenue`);
};

export const getFile = (fileUrl: string, filename: string) => {
    return api.post(
        `/files/`,
        { fileUrl, filename },
        {
            responseType: 'blob',
        }
    );
};

export const removeFile = (filename: string) => {
    return api.delete(`/files/${filename}`);
};

export const checkPseudo = (pseudo: string) => {
    return api.put<{ pseudoTaken: boolean }>(`/users/pseudo/${pseudo}`);
};

export const createConnectedAccount = (account_token: string, country_code: string) => {
    return api.post<any>('/payments/account', { account_token, country_code });
};

export const getStripeOnboardingLink = () => {
    return api.get<{ url: string }>('/payments/account/link/onboarding');
};

export const getStripeUpdateLink = () => {
    return api.get<{ url: string }>('/payments/account/link/update');
};

export const getPayoutData = () => {
    return api.get<PayoutData>('/payments/account');
};

export const sendMessage = (message: string) => {
    return api.post<any>('/contact', { message });
};

export const getAdminData = () => {
    return api.get<AdminData>('/admin/stats');
};

type GetNotificationsFn = (
    userId: string,
    cursor?: number,
    limit?: number,
    types?: NotificationType[]
) => Promise<AxiosResponse<NotificationsData>>;

export const getNotifications: GetNotificationsFn = (
    userId,
    cursor,
    limit,
    types
) => {
    const params: Record<string, any> = {
        userId,
        cursor,
        limit
    };

    if (types && types.length > 0) {
        // On convertit le tableau de types en une seule string, séparée par des virgules
        params.types = types.join(',');
    }

    return api.get<NotificationsData>('/notifications', {
        params
    });
};

export const markPostNotifsAsClicked = (postId: string) => {
    return api.put(`/notifications/click/${postId}`);
};

export const markNotifsAsSeen = () => {
    return api.put('/notifications/seen');
};

export const markNewSubscriberNotifAsClicked = (userId: string) => {
    return api.put(`/notifications/click/newSubscription/${userId}`);
};

export const getSubscriptions = (limit: number = 3, cursor?: number) => {
    return api.get<Connection[]>(
        `/users/mentors/subscriptions?limit=${limit}&cursor=${cursor || ''}`
    );
};

export const getPayments = (limit: number = 3, cursor?: number) => {
    return api.get<Payments[]>(
        `/users/mentors/payments?limit=${limit}&cursor=${cursor || ''}`
    );
};

export const getTransfers = () => {
    return api.get<Transfer[]>('/users/mentors/transfers');
};

export const runScript = () => {
    return api.put('/admin/script');
};

export const checkPromoCode = (code: string, traderId: string) => {
    return api.put<PromoCodeData>(
        `/payments/promocode/${code}/mentor/${traderId}`
    );
};

export const getMarketStatus = (code: string) => {
    return api.get<{ isOpen: Boolean }>(`/markets/${code}/status`);
};

export const getCheckoutSubscription = (traderId: string, customerId: string, price: string, commitment: string) => {
    return api.post<string>(`/subscriptions/checkout/${traderId}`, { customerId, price, commitment });
};

export const getPaymentPrice = (traderId: string) => {
    return api.get<{ paymentPrice: string }>(
        `/subscriptions/${traderId}`
    );
};
