export enum Role {
    TRADER = 'TRADER',
    MENTEE = 'MENTEE',
    ADMIN = 'ADMIN',
}

export enum TagType {
    STOCK= 'STOCK',
    CRYPTO = 'CRYPTO',
    FOREX = 'FOREX',
    COMMODITY = 'COMMODITY',
    INDICES = 'INDICES',
    SHORT_TERM = 'SHORT TERM',
    LONG_TERM = 'LONG TERM',
}

export enum UserOrigin {
    GOOGLE = 'GOOGLE',
    EMAIL_PASSWORD = 'EMAIL_PASSWORD',
}

export type User = {
    id: string;
    createdAt: Date;
    email: string;
    name: string;
    picture?: string | null;
    role: Role;
    capital: number;
    startCapital?: number;
    totalCapital?: number;
    description?: string;
    pseudo?: string;
    firstName?: string;
    lastName?: string;
    roi?: { date: any; roi: any }[];
    connectedAccId?: string | null;
    price?: string;
    superUserKey?: string;
    language?: Language;
    comission?: number;
    bankFees?: number;
    subscriptionFormula?: SubscriptionFormula;
    tags?: TagType[];
};

export enum PostSection {
    ORDER = 'ORDER',
    ANALYSIS = 'ANALYSIS',
    VIDEO = 'VIDEO',
    AUDIO = 'AUDIO',
}

export enum Language {
    English = 'EN',
    French = 'FR',
}

export enum SubscriptionFormula {
    TRIMESTER = 'TRIMESTER',
    SEMESTER = 'SEMESTER',
}

export interface AnalysisRaw {
    text: string;
    images?: File[];
}

export type Analysis = {
    text: string;
    imageUrl?: string  | null;
    imageUrl2?: string | null;
    imageUrl3?: string | null;
};

export interface CloseAnalysis {
    comment?: string;
    imageUrl?: string;
    imageUrl2?: string;
    imageUrl3?: string;
}

export type Order = {
    selectedProduct?: ProductInfo & CryptoInfo;
    position?: PositionType;
    capital?: number | string;
    symbol?: string;
    name?: string;
    exchange?: string;
    image?: string | null;
    price?: number;
    percent?: number;
    currency?: string;
    id?: string;
    status?: OrderStatus;
    sellingPrice?: number;
    profit?: number;
    closedAt?: Date;
    closeAnalysis?: CloseAnalysis | null;
    takeProfit?: number | null;
    stopLoss?: number | null;
    post?: Post;
};

export type CommentUser = Pick<
    User,
    'name' | 'picture' | 'id' | 'pseudo' | 'role'
> & {
    // _count: { posts: number };
};

export type Reply = {
    id: number;
    createdAt: Date;
    commentId: number;
    text: string;
    userId: string;
    user: User;
};

export type Comment = {
    id: number;
    postId: string;
    createdAt: Date;
    text: string;
    userId: string;
    user: CommentUser;
    _count: {
        replies: number;
    };
};

export type Post = {
    id: string;
    createdAt: Date;
    authorId: string;
    analysis?: Analysis;
    order?: Order;
    author: User;
    likes: { userId: string }[];
    favoritePosts?: { userId: string }[];
    comments: Comment[];
    _count: { likes: number; comments: number };
    videoUrl?: string;
    audioUrl?: string;
    isRestricted?: boolean;
    isFreePost?: boolean;
    isFree?: boolean;
    isFollowingAuthor?: boolean;
};

export type ProductResult = {
    cryptoSymbol?: string;
    symbol: string;
    name: string;
    thumb: string;
    currency: string;
    stockExchange: string;
    exchangeShortName: string;
};

export interface ProductInfo {
    symbol: string;
    price: number;
    beta: number;
    volAvg: number;
    mktCap: number;
    lastDiv: number;
    range: string;
    changes: number;
    companyName: string;
    currency: string;
    cik: string;
    isin: string;
    cusip: string;
    exchange: string;
    exchangeShortName: string;
    industry: string;
    website: string;
    description: string;
    ceo: string;
    sector: string;
    country: string;
    fullTimeEmployees: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    dcfDiff: number;
    dcf: number;
    image: string | null;
    ipoDate: string;
    defaultImage: boolean;
    isEtf: boolean;
    isActivelyTrading: boolean;
    isAdr: boolean;
    isFund: boolean;
    isMarketOpen?: boolean;
    isDelayedMarket?: boolean;
}

export interface CryptoInfo {
    symbol: string;
    name: string;
    price: number;
    changesPercentage: number;
    change: number;
    dayLow: number;
    dayHigh: number;
    yearHigh: number;
    yearLow: number;
    marketCap: number;
    priceAvg50: number;
    priceAvg200: number;
    volume: number;
    avgVolume: number;
    exchange: string;
    open: number;
    previousClose: number;
    eps?: any;
    pe?: any;
    earningsAnnouncement?: any;
    sharesOutstanding: number;
    timestamp: number;
}

export enum PositionType {
    LONG = 'LONG',
    SHORT = 'SHORT',
}

export enum OrderStatus {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
}

export interface Historical {
    date: string;
    open: number;
    high: number;
    low: number;
    close: number;
    adjClose: number;
    volume: number;
    unadjustedVolume: number;
    change: number;
    changePercent: number;
    vwap: number;
    label: string;
    changeOverTime: number;
}

export interface PriceHistory {
    symbol: string;
    historical: Historical[];
}

export enum ProductCategory {
    STOCK = 'STOCK',
    CRYPTO = 'CRYPTO',
    FOREX = 'FOREX',
    COMMODITY = 'COMMODITY',
}

export interface GetPostOptions {
    onlyOrders?: boolean;
}

export interface GetPostsParams {
    userId: string;
    limit?: number;
    cursor?: string;
    options?: GetPostOptions;
    orderStatus?: OrderStatus;
    orderBy?: 'closedAt';
    superUserKey?: string;
}

export interface GetUserProfileResponse {
    profile: User & {
        _count: {
            posts: number;
            // mentees: number;
        };
        mentees: {
            menteeId: string;
            status: SubscriptionStatus;
            paidUntil: Date | null;
            activationDate: Date | null;
        }[];
    };
    weekPostsCount: number;
    weekMenteesCount: number;
    commentsCount: number;
    weekCommentsCount: number;
    promo: Promo | null;
    minCommitment: number;
}

export type RoiHistory = { date: Date; roi: number };

export type Exchange =
    | 'TSX'
    | 'AMEX'
    | 'NASDAQ'
    | 'NYSE'
    | 'EURONEXT'
    | 'XETRA'
    | 'NSE'
    | 'LSE'
    | 'COMMODITY'
    | 'CRYPTO'
    | 'FOREX'
    | 'STO'
    | 'SHZ'
    | 'HEL'
    | 'SHH'
    | 'JPX'
    | 'OSE'
    | 'SIX'
    | 'HKSE'
    | 'CPH'
    | 'MIL'
    | 'KSC'
    | 'TAI';
export interface MarketGroup {
    _count: number;
    exchange: Exchange;
}
export interface PositionGroup {
    _count: number;
    position: PositionType;
}
export interface BreakdownStats {
    marketGroup: MarketGroup[];
    positionGroup: PositionGroup[];
}

export interface FinancialStats {
    bestRoi: number;
    avgRoi: number;
    avgInvestedCapital: number;
    avgDuration: number;
    winRatio: number;
}

export interface PostTypeStats {
    postsCount: number;
    analysisCount: number;
    ordersCount: number;
    videosCount: number;
    audiosCount: number;
}

export type GetUserStatsResponse = BreakdownStats &
    FinancialStats &
    PostTypeStats;

export interface Like {
    id: number;
    postId: string;
    userId: string;
    createdAt: Date;
    relationId: string;
    user: User;
}

export interface SubscriptionResponse {
    customerId: string;
    // subscriptionId: string;
    // clientSecret: string;
    // paymentAmount: number;
}

export enum SubscriptionStatus {
    NEW = 'NEW',
    ACTIVE = 'ACTIVE',
    CANCELED = 'CANCELED',
    DISABLED = 'DISABLED',
}
export interface MonthlyRevenue {
    revenue: number;
    year: number;
    month: number;
}

export interface PayoutData {
    hasConnectedAccount: boolean;
    currently_due?: string[];
    payouts_enabled?: boolean;
    disabled_reason?: string;
    errors?: string[];
}

export enum Promo {
    ONE_EUR = 'ONE_EUR',
    SECOND15 = 'SECOND15',
}

interface PerDay {
    count: number;
    day: string;
    total: number;
    [key: string]: any;
}

export interface AdminData {
    menteesCount: number;
    tradersCount: number;
    activeSubsCount: number;
    menteesPerDay: PerDay[];
    postsPerDay: PerDay[];
    commmentsPerDay: PerDay[];
    subscriptionsPerDay: PerDay[];
}

export enum NotificationType {
    NEW_COMMENT = 'NEW_COMMENT',
    NEW_REPLY = 'NEW_REPLY',
    NEW_POST = 'NEW_POST',
    NEW_SUBSCRIPTION = 'NEW_SUBSCRIPTION',
    NEW_LIKE = 'NEW_LIKE',
    CLOSE_TRADE = 'CLOSE_TRADE',
}

export type Notification = {
    id: number;
    createdAt: Date;
    sourceUserId: string;
    targetUserId: string;
    postId: string;
    commentId: number | null;
    type: NotificationType;
    seen: boolean;
    clicked: boolean;
    sourceUser: User;
};

export interface NotificationsData {
    notifications: Notification[];
    unreadCount: number;
    hasMore: boolean;
    nextCursor: number | null;
}

export type Connection = {
    id: number;
    createdAt: Date;
    status: SubscriptionStatus;
    paidUntil: Date;
    price: string;
    mentee: string;
};

export type Payments = {
    id: number;
    createdAt?: Date;
    userId: string;
    menteeId: string | null;
    amount: number;
    month: number;
    year: number;
    mentee: {
        pseudo: string;
    } | null;
};

export type Transfer = {
    id: string;
    amount: number;
    created: number;
};

export interface PromoCodeData {
    active: boolean;
    code: string;
    amount_off: number;
    percent_off: number;
    duration: string;
    metadata: {
        button_message?: string;
        other_message?: string;
        success_message?: string;
    };
}
